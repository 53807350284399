<template>
  <!-- 
    画面: 掲載サイト一覧
    用途: 掲載サイトの一覧を参照する
   -->
  <div>

    <!-- #region List -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{ $t("siteListPage.pageTitle") }}</strong>
            <CButton
              color="info"
              @click="onCreateClicked()"
            >{{ $t("siteListPage.create") }}</CButton>
          </CCardHeader>
          <CCardBody>
            <!-- #region Table -->
            <CDataTable
              border
              hover
              size="sm"
              :fields="tableFields"
              :items="tableItems"
            >
              <td
                slot="shortName"
                slot-scope="{ item }"
              >
                <span class="ml-2 text-left">{{ item.shortName }}</span>
              </td>
              <td
                slot="name"
                slot-scope="{ item }"
              >
                <span class="ml-2 text-left">{{ item.name }}</span>
              </td>
              <td
                slot="code"
                slot-scope="{ item }"
              >
                <span class="ml-2 text-left">{{ item.code }}</span>
              </td>
              <td
                slot="url"
                slot-scope="{ item }"
              >
                <span class="ml-2 text-left">{{ item.url }}</span>
              </td>
              <td
                slot="status"
                slot-scope="{ item }"
                class="text-center p-0"
              >
                <CSwitch
                  class="mt-2 swc sw-2"
                  color="info"
                  variant="opposite"
                  :labelOn='$t("common.valid")'
                  :labelOff='$t("common.invalid")'
                  :checked="item.status | masterStatus"
                  @update:checked="onStatusChanged(item)"
                />
              </td>
              <template #action="{ item }">
                <td class="p-0 text-center">
                  <CButton
                    color="info"
                    square
                    size="sm"
                    class="px-3 mt-2"
                    @click="onEditClicked(item.id)"
                  ><i class="icon cil-pencil mr-1"></i> {{ $t("common.edit") }}</CButton>
                </td>
              </template>
            </CDataTable>
            <!-- #endregion Table -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion List -->

  </div>
</template>

<script>
export default {

  name: "SiteList",

  data() {
    return {
      //#region Table
      /** 一覧列名 */
      tableFields: [
        { key: "shortName", label: "略称", _classes: "text-center bg-light" },
        { key: "name", label: "名称", _classes: "text-center bg-light" },
        { key: "code", label: "コード", _classes: "text-center bg-light" },
        { key: "url", label: "URL", _classes: "text-center bg-light" },
        {
          key: "status",
          label: "有効／無効",
          _classes: "text-center bg-light",
        },
        {
          key: "action",
          label: "アクション",
          _classes: "text-center bg-light",
        },
      ],

      tableItems: [],
      //#endregion Table
    };
  },

  methods: {
    //#region Event
    onCreateClicked() {
      this.$router.push({
        path: `/admin/site/create`
      });
    },

    onStatusChanged(item) {
      item.status = this.toggleStatus(item.status);
      this.reqPutStatus(item);
    },

    onEditClicked(siteId) {
      this.$router.push({
        path: `/admin/site/${siteId}/edit`,
      });
    },
    //#endregion Event


    //#region Request
    reqGet() {
      const callback = (a) => this.pourTable(a.sites);
      
      this.reqGetSiteAll(callback);
    },

    reqPutStatus(item) {
      const body = { status: item.status };
      const callback = () => {
        this.successModal = true;
        this.reqGet();
      };
      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPutSiteStatus(item.id, body, callback, errorCallback);
    },
    //#endregion Request

    //#region Method
    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach(data => {
        this.tableItems.push(
          {
            id: data.id,
            shortName: data.shortName,
            name: data.name,
            code: data.code,
            url: data.url,
            status: data.status, // 100: valid, 200: invalid, 201: new
          }
        );
      });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },

}
</script>
