var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"md":"12","lg":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',{staticClass:"card-title mr-3"},[_vm._v(_vm._s(_vm.$t("siteListPage.pageTitle")))]),_c('CButton',{attrs:{"color":"info"},on:{"click":function($event){return _vm.onCreateClicked()}}},[_vm._v(_vm._s(_vm.$t("siteListPage.create")))])],1),_c('CCardBody',[_c('CDataTable',{attrs:{"border":"","hover":"","size":"sm","fields":_vm.tableFields,"items":_vm.tableItems},scopedSlots:_vm._u([{key:"shortName",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.shortName))])])}},{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.name))])])}},{key:"code",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.code))])])}},{key:"url",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.url))])])}},{key:"status",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center p-0"},[_c('CSwitch',{staticClass:"mt-2 swc sw-2",attrs:{"color":"info","variant":"opposite","labelOn":_vm.$t("common.valid"),"labelOff":_vm.$t("common.invalid"),"checked":_vm._f("masterStatus")(item.status)},on:{"update:checked":function($event){return _vm.onStatusChanged(item)}}})],1)}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"p-0 text-center"},[_c('CButton',{staticClass:"px-3 mt-2",attrs:{"color":"info","square":"","size":"sm"},on:{"click":function($event){return _vm.onEditClicked(item.id)}}},[_c('i',{staticClass:"icon cil-pencil mr-1"}),_vm._v(" "+_vm._s(_vm.$t("common.edit")))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }